import { Switch } from "@headlessui/react";
import React from "react";

import { state } from "./provider";

export default function DarkModeToggle() {
  return (
    <state.Consumer>
      {(context) => (
        <Switch
          checked={context?.isDark}
          onChange={() => context?.changeTheme()}
          className={`${
            context?.isDark ? "bg-gray-700" : "bg-gray-300"
          } relative inline-flex items-center h-6 rounded-full w-11 mr-4`}
        >
          <span
            className={`${
              context?.isDark
                ? "translate-x-6 bg-black"
                : "translate-x-1 bg-white"
            } inline-block w-4 h-4 transform rounded-full`}
          />
        </Switch>
      )}
    </state.Consumer>
  );
}

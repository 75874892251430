import { Link as ClientLink } from "@reach/router";
import { Link } from "gatsby";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import Modal from 'react-modal';

import Badge from './badge';
import Button from './button';
import DarkModeToggle from "./dark-mode-toggle";
import ImageEnlarger from "./image-enlarger";
import { state } from "./provider";
import { getProfile, logout } from "../services/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import SEO from "./seo";
import { useWindowSize } from "react-use";
import hero from "../images/learn-more-classy.jpeg";

function Logo() {
  return (
    <Link className="text-base font-heading tracking-widest" to="/app">
      periodical
    </Link>
  );
}

const routes = [
  { name: "Home", to: "/app" },
  { name: "Library", to: "/app/library" },
  { name: "Following", to: "/app/following" },
];

const isBrowser = typeof window !== 'undefined';

function isRouteActive({ to }) {
  const current = isBrowser ? window.location?.pathname : null;
  return to === current;
}

function Navigation({ hasSubscription, isStatic }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowSize();

  const isMobile = width <= 640;

  return (
    <div className="transition-all duration-75 delay-75 ease-out flex justify-between items-center h-16 w-full border-b-2 border-gray-100 border-solid">
      <div className="flex h-full w-4/12 items-center">
        <div className="h-full flex flex-col items-center justify-center px-4 border-solid border-r-2 border-gray-100">
          <Logo />
        </div>
        {(!isStatic && !hasSubscription) && (
          <Badge persist className="ml-4 hidden md:flex">Get Classy</Badge>
        )}
      </div>
      {!isStatic && (
        <div className="flex items-center px-8 relative">
          <DarkModeToggle />
          {!isMobile && routes.map((route, index) => (
            <ClientLink
              className={`${index > 0 ? "ml-4" : ""} ${
                isRouteActive(route)
                  ? "text-gray-700 dark:text-white"
                  : "dark:text-gray-400 text-gray-500 hover:underline"
              }`}
              key={route.name}
              to={route.to}
            >
              {route.name}
            </ClientLink>
          ))}
          <FontAwesomeIcon
            className="mt-1 ml-4 dark:text-gray-400 text-gray-500 hover:underline"
            icon={isMenuOpen ? faTimes : faBars}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          />
          {isMenuOpen && (
            <div className="z-10 flex flex-col items-center justify-between bg-white dark:text-black rounded-sm border-black dark:border-white border-2 border-solid top-8 right-8 absolute w-max px-8 py-4 h-max">
              {isMobile && routes.map((route, index) => (
                <ClientLink
                  className={`mb-2 dark:text-black ${
                    isRouteActive(route)
                      ? "underline"
                      : "hover:underline"
                  }`}
                  key={route.name}
                  to={route.to}
                >
                  {route.name}
                </ClientLink>
              ))}
              <ClientLink
                className={`mb-2 dark:text-black ${
                  isRouteActive("/app/edit-profile")
                    ? "underline"
                    : "hover:underline"
                }`}
                to={"/app/edit-profile"}
              >
                Edit Profile
              </ClientLink>
              <ClientLink
                className={`mb-2 dark:text-black ${
                  isRouteActive("/app/roadmap")
                    ? "underline"
                    : "hover:underline"
                }`}
                to={"/app/roadmap"}
              >
                Roadmap
              </ClientLink>
              <a className="mb-2 dark:text-black hover:underline" href="mailto:me@michaelmang.dev">
                Support
              </a>
              <a
                className="dark:text-black hover:underline"
                href="#logout"
                onClick={(e) => {
                  logout();
                  e.preventDefault();
                }}
              >
                Log Out
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function getTheme(isDark = false) {
  return isDark ? "dark" : "";
}

export default function Layout({ children, className, isStatic, style = {}, title, image }) {
  const mainStyle = { minHeight: "calc(100vh - 133px)" };
  const modalStyle = {
    content: {
      inset: 10,
    },
  };

  const authenticatedUser = getProfile();
  const hasSubscription = !isEmpty(authenticatedUser?.subscriptions);

  return (
    <state.Consumer>
      {(context) => (
        <>
          <SEO
            image={image}
            title={title}
          />
          <div
            style={style}
            className={`${className} ${getTheme(
              context?.isDark
            )} flex flex-col items-center min-h-screen w-full overflow-x-hidden font-body`}
          >
            <Navigation hasSubscription={hasSubscription} isStatic={isStatic} />
            <main
              className={`flex flex-col md:flex-row max-w-screen-lg w-full px-4 md:px-0 py-16 overflow-x-hidden`}
              style={mainStyle}
            >
              {children}
            </main>
            <footer className="p-8 w-full flex items-center justify-end justify-self-end max-w-screen-lg">
              <Logo />
              {(!isStatic && !hasSubscription) && (
                <Badge persist className="ml-4 md:hidden">Get Classy</Badge>
              )}
            </footer>
          </div>
          <Modal style={modalStyle} isOpen={context?.isClassyModalOpen}>
            <div className="flex flex-col items-center h-full w-full overflow-x-hidden font-body">
              <div className="p-6 md:p-0 flex flex-col items-center max-w-screen-md w-full border-black border-2 border-solid">
                <div onClick={context?.toggleClassyModal} className="cursor-pointer flex items-center pt-12 pb-4 text-5xl font-heading">periodical <Badge persist className="ml-4" /> </div>
                <div className="flex text-center flex-row justify-center pb-3 text-lg font-heading border-double border-b-2 border-black w-full">
                  Get a classier experience for less than the cost of Starbucks, only charged once a month.
                </div>
                <div className="flex flex-row justify-center items-center py-3 text-base font-heading border-double border-b-2 border-black w-full">
                  $2.99/month
                  <span className="mx-4">|</span>
                  {moment().format("LL")}
                </div>
                <div className="py-8 flex flex-col items-center w-full">
                  <div className="flex flex-row justify-center pb-8">
                    <Link to="/app/classy">
                      <h1 className="text-5xl md:text-7xl text-center font-bold font-heading hover:underline">
                        Excuse me, but you'll need <span className="text-yellow-400 text-7xl">Classy</span> to play with this feature
                      </h1>
                    </Link>
                  </div>
                  <div className="flex flex-row justify-center px-8">
                    <ImageEnlarger
                      className="shadow-sm object-cover object-center h-auto w-full filter grayscale"
                      alt="periodical"
                      src={hero}
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <Link className="pt-8 pb-4" to="/app/classy">
                      <Button className="px-3 py-2 ring ring-black ring-offset-2">
                        Learn more about Classy
                      </Button>
                    </Link>
                    <div className="text-center text-gray-700 hover:underline cursor-pointer" onClick={context?.toggleClassyModal}>
                      I'll pass on a classier experience for now.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </state.Consumer>
  );
}
